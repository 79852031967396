import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import YupPassword from "yup-password";
import * as Yup from "yup";
import MyTextField from "../components/fields/MyTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Loading from "../components/Loading";
import { Typography } from "@material-ui/core";
import Footer from "../components/nav/Footer";
import Navbar from "../components/nav/Nav";
import Message from "../components/authenticated/Message";
import { resetPassword } from "../api";
import { SITE_KEY } from "../helper";
import { lighten } from "@material-ui/core/styles";
import { loadRecaptcha } from "../helper";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import actions from "../redux/actions";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import "../helper/schemes/globalScheme";

import { texts } from "../data/texts";
import { responseMessages } from "../helper/responseHelper";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie Ihre Email ein."),
  password: Yup.string()
    .isValidPassword()
    .required("Bitte geben Sie ein Passwort ein."),

  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Die angegebenen Passwörter stimmen nicht überein."
    )
    .required("Bitte geben Sie Ihr Passwort nochmal ein."),
  token: Yup.string(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    paddingTop: "5rem",
  },
  login: {
    minHeight: "52vh",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  btn: {
    textAlign: "center",
  },
}));

const ResetPassword = ({ location }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  let initialValues = {
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  };

  useEffect(() => {
    if (location?.href) {
      const url = new URL(location.href);
      if (url.searchParams.get("token")) {
        initialValues.token = url.searchParams.get("token");
      } else {
        if (typeof window !== "undefined") {
          navigate("/forgot-password");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (dirty) {
      loadRecaptcha();
    }
  }, [dirty]);

  const submitData = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "thgresetpassword" })
        .then((token) => requestToServer(data, token));
    });
  };

  const requestToServer = (data, token) => {
    setLoading(true);

    resetPassword(data).then((resp) => {
      setLoading(false);
      if (resp?.success != null) {
        const response = responseMessages(resp?.error_code);

        dispatch(
          actions.message.addMessage({
            type: response["type"],
            title: response["title"],
            description: response["message"],
          })
        );
        if (resp?.success === true) {
          dispatch(
            actions.message.addMessage({
              type: "success",
              title: texts["passwordChangedTitle"],
              description: texts["passwordChanged"],
            })
          );
          if (typeof window !== "undefined") {
            navigate("/login");
          }
        }
      }
    });
  };

  if (loading) return <Loading />;

  return (
    <div className={classes.root}>
      <Navbar />
      <Box my={10}>
        <Container component="main" maxWidth="sm">
          <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values, formikProps) => {
              // same shape as initial values
              submitData(values, formikProps);
            }}
          >
            {(props) => {
              if (!dirty && props.dirty) {
                setDirty(true);
              }
              return (
                <Form className={classes.login}>
                  <Message />
                  <CssBaseline />
                  <div className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Passwort zurücksetzen!
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Bitte gib deine E-Mail-Adresse ein und erstelle ein
                          neues Passwort
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <MyTextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="email"
                          label="E-Mail Adresse"
                          id="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MyTextField
                          variant="outlined"
                          fullWidth
                          name="password"
                          label="Passwort"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MyTextField
                          variant="outlined"
                          fullWidth
                          name="password_confirmation"
                          label="Passwort wiederholen"
                          type="password"
                          id="password_confirmation"
                          autoComplete="current-password"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.btn}
                        justifyContent="flex-end"
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          Senden
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Box mt={8}></Box>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default ResetPassword;
